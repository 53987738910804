import React from 'react';
import loadable from '@loadable/component';
import {graphql} from 'gatsby';
import {Location} from '@reach/router';


const PageHeader = loadable(() => import('../components/PageHeader'));
const Layout = loadable(() => import('../components/Layout'));
const DocumentCard = loadable(() => import('../components/document/DocumentCard'));

// Export Template for use in CMS preview
export const DocumentsPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  documentFiles
}) => (
  <Location>
    {() => {
      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subtitle}
            backgroundImage={featuredImage}
          />

          <section className="section mb-8">
            <div className="container">
              <h2>Préparer votre venue dans notre cabinet</h2>
              <hr className="mb-8"></hr>
              {!!documentFiles.length && (
                  <div className="DocumentSection--Grid flex mt-8 mb-8 flex-wrap justify-center">
                    {documentFiles.map((document, index) => (
                        <DocumentCard key={document.title + index} {...document} />
                    ))}
                  </div>
              )}
            </div>
          </section>



        </main>
      )
    }}
  </Location>
);




const DocumentsPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DocumentsPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
    />
  </Layout>
);

export default DocumentsPage

export const pageQuery = graphql`
  query DocumentsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
        documentFiles {
          href
          title
          text
        }
      }
    }
  }
`;

